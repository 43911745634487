import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  getLanguage,
  changeLanguage,
  getLocation,
  setLocation,
  setAutoHeightAdjust,
} from "../../store/rootReducer";
import { useHistory } from "react-router";
import { labelsMap } from "../../utils/i18n";
import ScreenKeyboard from "containers/keyboard/screenKeyboard"; // Import the keyboard component
import {
  openKeyboard,
  closeKeyboard,
  updateInputValue,
} from "containers/keyboard/action";
import deviceControl from "services/deviceControl";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
export default function Home() {
  const language = useSelector(getLanguage);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useSelector(getLocation);
  const [autoHeightAdjust, setHeightAdjust] = useState(false);

  const handleCheckboxChange = (event) => {
    setHeightAdjust(event.target.checked);
  };
  const { HOME_HEADING, HOME_SUBHEADING } = labelsMap[language];
  const handleLocationFocus = (event) => {
    dispatch(openKeyboard(event.target.id, event.target.value));
  };
  useEffect(() => {
    deviceControl.resetServo();
    deviceControl.reset();

    deviceControl.ledOff();
  }, []);

  const CustomCheckbox = withStyles({
    root: {
      "& svg": {
        fontSize: 30,
      },
    },
  })(Checkbox);
  const handleLocationBlur = (event) => {
    dispatch(closeKeyboard());
  };

  const handleLocationChange = (e) => {
    dispatch(updateInputValue(e.target.value));
  };
  const handleLocationSubmit = (event) => {
    event.preventDefault();
    if (locationValue !== "") {
      dispatch(setLocation(locationValue));
      dispatch(setAutoHeightAdjust(autoHeightAdjust));
      localStorage.setItem("location", locationValue);
    } else {
      alert("Please enter a location");
    }
  };

  const locationValue = useSelector((state) =>
    state.keyboard.currentInput === "location-input" ? state.keyboard.value : ""
  );
  return (
    <>
      <div
        style={{
          padding: 10,
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container style={{ flex: 1 }}>
          <Grid
            item
            xs={12}
            md={12}
            lg={6}
            x1={6}
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                alt="logo"
                style={{ width: "25%", objectFit: "contain" }}
                src={process.env.PUBLIC_URL + "/images/logo.png"}
              />
              <div style={{ textAlign: "left", padding: "2em" }}>
                <div className="heading1" style={{ textAlign: "left" }}>
                  {HOME_HEADING}
                </div>
                <div style={{ height: "2em" }}></div>
                <div className="heading2" style={{ textAlign: "left" }}>
                  {HOME_SUBHEADING}
                </div>
                <div style={{ height: "2em" }}></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ width: "30%" }}>
                    <Button
                      style={{ backgroundColor: "#052679", color: "white" }}
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        dispatch(changeLanguage("marathi"));
                        history.push("/login");
                      }}
                    >
                      <span style={{ fontSize: "1.5em" }}>मराठी</span>
                    </Button>
                  </div>
                  <div style={{ width: "30%" }}>
                    <Button
                      style={{ backgroundColor: "#052679", color: "white" }}
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        dispatch(changeLanguage("tamil"));
                        history.push("/login");
                      }}
                    >
                      <span style={{ fontSize: "1.5em" }}>தமிழ்</span>
                    </Button>
                  </div>
                  <div style={{ width: "30%" }}>
                    <Button
                      style={{ backgroundColor: "#052679", color: "white" }}
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        dispatch(changeLanguage("hindi"));
                        history.push("/login");
                      }}
                    >
                      <span style={{ fontSize: "1.5em" }}>हिंदी</span>
                    </Button>
                  </div>
                  <div style={{ width: "30%" }}>
                    <Button
                      style={{ backgroundColor: "#052679", color: "white" }}
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        dispatch(changeLanguage("english"));
                        history.push("/login");
                      }}
                    >
                      <span style={{ fontSize: "1.5em" }}>English</span>
                    </Button>
                  </div>
                </div>
              </div>
              <div style={{ height: "2em" }}></div>
            </div>
          </Grid>
          <Grid
            className="slider-container"
            item
            xs={12}
            md={12}
            lg={6}
            x1={6}
            style={{ display: "flex" }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "5%",
                boxSizing: "border-box",
              }}
            >
              <video
                key={language + "-video"}
                muted
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                loop
                autoPlay
              >
                <source
                  src={"./videos/" + language + ".mp4"}
                  type="video/mp4"
                />
              </video>
            </div>
          </Grid>
        </Grid>
        {location === "" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: "0%",
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              zIndex: 1000,
            }}
          >
            <TextField
              id="location-input"
              placeholder="Enter location"
              value={locationValue}
              onChange={handleLocationChange}
              onFocus={handleLocationFocus}
              onBlur={handleLocationBlur}
              style={{
                margin: "20px 0",
                width: "80%",
                backgroundColor: "#f0f0f0",
                textAlign: "center",
                fontSize: "1.5em",
              }}
              inputProps={{
                style: {
                  textAlign: "center",
                  fontSize: "1.5em",
                },
              }}
            />
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={autoHeightAdjust}
                  onChange={handleCheckboxChange}
                  color="white"
                />
              }
              label={
                <Typography variant="body1" style={{ color: "#f0f0f0" }}>
                  Auto Height Adjust Supported
                </Typography>
              }
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleLocationSubmit}
            >
              Submit
            </Button>
          </div>
        )}
      </div>
      <ScreenKeyboard />
    </>
  );
}
