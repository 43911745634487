export const labelsMap = {
  // TEST_MODE: "VIDEO",
  marathi: {
    HOME_HEADING: "तुमचे हसू सुरक्षित करण्याचा एक सोपा, स्मार्ट मार्ग",
    HOME_SUBHEADING: "भाषा निवडा",
    LOGIN_HEADING: "नमस्कार!",
    LOGIN_SUBHEADING: "चला निरोगी तोंडाकडे आपला प्रवास सुरू करूया.",
    LOGIN_INPUT_NAME: "नाव",
    LOGIN_INPUT_MOBILE: "मोबाईल नंबर",
    LOGIN_INPUT_OTP: "OTP",
    LOGIN_BUTTON_SEND_OTP: "OTP पाठवा",
    LOGIN_BUTTON_SUBMIT_OTP: "OTP सबमिट करा",
    LOGIN_BUTTON_RESEND_OTP: "OTP मिळाला नाही? पुन्हा पाठवा",
    LOGIN_BUTTON_EDIT_NUMBER: "क्रमांक संपादित करा", //Edit Number
    LOGIN_MESSAGE_OTP_SENT: "OTP पाठवला", //OTP Sent
    LOGIN_MESSAGE_INVALID_OTP: "कृपया वैध OTP प्रदान करा",
    LOGIN_MESSAGE_OTP_ERROR: "OTP पाठवताना काहीतरी चूक झाली",
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR: "OTP सबमिट करताना काहीतरी चूक झाली",
    LOGIN_MESSAGE_INVALID_DATA: "कृपया वैध डेटा भरा",
    SCAN_HEADING_TEETH: "दात",
    SCAN_INSTRUCTIONS_MAP: {
      front: [
        "मार्गदर्शक व्हिडिओ काळजीपूर्वक पहा",
        "फ्रेममध्ये तुमचा समोरचे दात फिट करा",
        "फोटो काढण्याचे बटण दाबा",
      ],
      upper: [
        "मार्गदर्शक व्हिडिओ काळजीपूर्वक पहा",
        "फ्रेममध्ये तुमचे वरचे दात फिट करा",
        "फोटो काढण्याचे बटण दाबा",
      ],
      lower: [
        "मार्गदर्शक व्हिडिओ काळजीपूर्वक पहा",
        "फ्रेममध्ये तुमचे खालचे दात फिट करा",
        "फोटो काढण्याचे बटण दाबा",
      ],
    },
    SCAN_VIDEO_INSTRUCTIONS_MAP: {
      data: [
        "मार्गदर्शक व्हिडिओ काळजीपूर्वक पहा",
        "कॅमेऱ्याच्या शक्य तितक्या जवळ उभे रहा. व्हिडिओमध्ये दाखवल्याप्रमाणे तुमचे दात दाखवा",
        "सर्व 3 अँगल रेकॉर्ड झाल्यानंतर पॉज बटण क्लिक करा.",
      ],
    },
    SCAN_SUBMIT_BUTTON: "दात स्कॅन करा",
    ANGLE_LIST: ["समोरचे", "वरचे", "खालचे"],
    VIDEO_MODE: "विडिओ रेकॉर्ड करा",
    REPORT_HEADING: "दातांचा रिपोर्ट",
    REPORT_SUB_SECTION_HEADING:
      "रिपोर्टमध्ये आढळलेल्या समस्या आणि अंदाजे उपचार पर्याय",
    NO_DETECTIONS_IN_ANGLE:
      "कोणतीही आजारांची शोध आढळलेली नाही, जर छायाचित्र अयोग्य असेल तर पुन्हा सबमिट करण्याचा प्रयत्न करा.",

    BOOK_APPOINTMENT_BUTTON: "अपॉइंटमेंट बुक करा",
    DONWLOAD_REPORT_BUTTON: "रिपोर्ट डाउनलोड करा",
    EXIT_REPORT_BUTTON: "बाहेर पडा",
    APPOINTMENT_MESSAGE: "अपॉइंटमेंट बुक करण्यासाठी या नंबरवर कॉल करा",
    APPOINTMENT_SECOND_SECTION_HEADING:
      "स्कूल ऑफ डेंटल सायन्सेस येथील फास्ट ट्रॅक व सायंकाळचे क्लिनिक",
    APPOINTMENT_MORNING_SHIFT_TIMING: "सकाळी ९ ते संध्याकाळी ५ पर्यंत",
    APPOINTMENT_EVENING_SHIFT_TIMING: "संध्याकाळी ५ ते ८.३० पर्यंत",
    DOWNLOAD_HEADING1:
      "डेंटलदोस्त ऍप द्वारे मिळावा तुमचा दंत आरोग्य रिपोर्ट आणि योग्य सवयींचे पालन करा",
    DOWNLOAD_HEADING2: "खालील QR कोड स्कॅन करा आणि आत्ताच डाउनलोड करा",
  },

  hindi: {
    HOME_HEADING: "अपने दांतो की रक्षा करने का सब से आसान तरीका",
    HOME_SUBHEADING: "भाषा का चयन करें",
    LOGIN_HEADING: "नमस्ते!",
    LOGIN_SUBHEADING: "आइए एक स्वस्थ मुंह की ओर अपनी यात्रा शुरू करें।",
    LOGIN_INPUT_NAME: "नाम",
    LOGIN_INPUT_MOBILE: "मोबाइल नंबर",
    LOGIN_INPUT_OTP: "OTP",
    LOGIN_BUTTON_SEND_OTP: "OTP भेजें",
    LOGIN_BUTTON_SUBMIT_OTP: "OTP सबमिट करें",
    LOGIN_BUTTON_RESEND_OTP: "OTP नहीं मिला? पुन: भेजें",
    LOGIN_BUTTON_EDIT_NUMBER: "नंबर संपादित करें", //Edit Number
    LOGIN_MESSAGE_OTP_SENT: "OTP भेजा गया",
    LOGIN_MESSAGE_INVALID_OTP: "कृपया वैध OTP प्रदान करें",
    LOGIN_MESSAGE_OTP_ERROR: "OTP भेजते समय कुछ गलत हुआ",
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR: "OTP सबमिट करते समय कुछ गड़बड़ी हुई",
    LOGIN_MESSAGE_INVALID_DATA: "कृपया मान्य डेटा भरें",
    SCAN_HEADING_TEETH: "दांत",
    SCAN_INSTRUCTIONS_MAP: {
      front: [
        "बगल वाला वीडियो को ध्यान से देखें",
        "अपने सामने के जबड़े को फ्रेम में फिट करें",
        "क्लिक बटन को दबाये",
      ],
      upper: [
        "बगल वाला वीडियो को ध्यान से देखें",
        "अपने उपर के के जबड़े को फ्रेम में फिट करें",
        "क्लिक बटन को दबाये",
      ],
      lower: [
        "बगल वाला वीडियो को ध्यान से देखें",
        "अपने नीचे के जबड़े को फ्रेम में फिट करें",
        "क्लिक बटन को दबाये",
      ],
    },
    SCAN_VIDEO_INSTRUCTIONS_MAP: {
      data: [
        "बगल वाला वीडियो को ध्यान से देखें",
        "कैमरे के जितना हो सके पास खड़े रहें। वीडियो में दिखाए अनुसार अपने दांत दिखाएं।",
        "एक बार सभी 3 ऐंगल को रिकॉर्ड करने के बाद पॉज बटन पर क्लिक करें।",
      ],
    },
    SCAN_SUBMIT_BUTTON: "दांतों को स्कैन करें",
    ANGLE_LIST: ["सामनेके", "उपरके", "नीचेके"],
    VIDEO_MODE: "वीडियो रिकॉर्ड करें",
    REPORT_HEADING: "दंत आरोग्य रिपोर्ट",
    REPORT_SUB_SECTION_HEADING: "आपका अनुमानित उपचार विकल्प",
    NO_DETECTIONS_IN_ANGLE:
      "कोई बीमारी नहीं पाई गई, अगर छवि ठीक नहीं है तो कृपया उसे फिर से सबमिट करें।",
    BOOK_APPOINTMENT_BUTTON: "अपॉइंटमेंट बुक करें",
    DONWLOAD_REPORT_BUTTON: "रिपोर्ट डाउनलोड करें",
    EXIT_REPORT_BUTTON: "बाहर पडें",
    APPOINTMENT_MESSAGE: "अपॉइंटमेंट बुक करने के लिए इस नंबर पर कॉल करें",
    APPOINTMENT_SECOND_SECTION_HEADING:
      "स्कूल ऑफ डेंटल साइंसेज का फास्ट ट्रैक और शाम का क्लिनिक",
    APPOINTMENT_MORNING_SHIFT_TIMING: "सुबह ९ से शाम को ५ तक",
    APPOINTMENT_EVENING_SHIFT_TIMING: "शाम को ५ से ८.३० बजे तक",
    DOWNLOAD_HEADING1:
      "डेंटलडॉस्ट ऐप में पाइए आपका दंत आरोग्य रिपोर्ट और सही मौखिक स्वास्थ्य आदतों का पालन करें",
    DOWNLOAD_HEADING2: "इस QR कोड को स्कैन करें और अभी डाउनलोड करें",
  },

  english: {
    HOME_HEADING: "Simplest way to protect your teeth",
    HOME_SUBHEADING: "Select the language",
    LOGIN_HEADING: "Hi There!",
    LOGIN_SUBHEADING:
      "Let’s get your journey started towards a healthier mouth.",
    LOGIN_INPUT_NAME: "Name",
    LOGIN_INPUT_MOBILE: "Mobile Number",
    LOGIN_INPUT_OTP: "OTP",
    LOGIN_BUTTON_SEND_OTP: "Send OTP",
    LOGIN_BUTTON_SUBMIT_OTP: "Submit OTP",
    LOGIN_BUTTON_RESEND_OTP: "Didn’t get OTP? Resend",
    LOGIN_BUTTON_EDIT_NUMBER: "Edit Number",
    LOGIN_MESSAGE_OTP_SENT: "OTP Sent",
    LOGIN_MESSAGE_INVALID_OTP: "Please provide valid OTP",
    LOGIN_MESSAGE_OTP_ERROR: "Something went wrong while sending OTP",
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR: "Something went wrong while submitting OTP",
    LOGIN_MESSAGE_INVALID_DATA: "Please fill valid data",
    SCAN_HEADING_TEETH: "Teeth",
    SCAN_INSTRUCTIONS_MAP: {
      front: [
        "Watch the guiding video carefully",
        "Fit your front angle jaw in the frame",
        "Select the click button",
      ],
      upper: [
        "Watch the guiding video carefully",
        "Fit your upper angle jaw in the frame",
        "Select the click button",
      ],
      lower: [
        "Watch the guiding video carefully",
        "Fit your lower angle jaw in the frame",
        "Select the click button",
      ],
    },
    SCAN_VIDEO_INSTRUCTIONS_MAP: {
      data: [
        "Watch the guiding video carefully",
        "Stand as close as possible. Show your teeth as shown in the video.",
        "Click pause button once all 3 angles are recorded.",
      ],
    },
    SCAN_SUBMIT_BUTTON: "Scan teeth",
    ANGLE_LIST: ["Front", "Upper", "Lower"],
    VIDEO_MODE: "Record Video",
    REPORT_HEADING: "Your Dental Report",
    REPORT_SUB_SECTION_HEADING: "Your Estimated Treatment Options",
    NO_DETECTIONS_IN_ANGLE:
      "No diseases found. If the image is improper, please retry.",
    BOOK_APPOINTMENT_BUTTON: "Book Appointment",
    DONWLOAD_REPORT_BUTTON: "Download Report",
    EXIT_REPORT_BUTTON: "EXIT",
    APPOINTMENT_MESSAGE: "Call this number to book an appointment",
    APPOINTMENT_SECOND_SECTION_HEADING:
      "Fast Track & Evening Clinic at the School of Dental Sciences",
    APPOINTMENT_MORNING_SHIFT_TIMING: "Morning 9 am to Evening 5 pm",
    APPOINTMENT_EVENING_SHIFT_TIMING: "Evening 5 pm to 8.30 pm",
    DOWNLOAD_HEADING1:
      "Use DentalDost app to follow right oral health habits and hygiene practices",
    DOWNLOAD_HEADING2: "Scan this QR code and Download Now",
  },
  tamil: {
    HOME_HEADING: "உங்கள் பற்களை பாதுகாக்க எளிய வழி",
    HOME_SUBHEADING: "மொழியைத் தேர்ந்தெடுக்கவும்",
    LOGIN_HEADING: "வணக்கம்!",
    LOGIN_SUBHEADING: "ஆரோக்கியமான வாயை நோக்கி உங்கள் பயணத்தைத் தொடங்குவோம்.",
    LOGIN_INPUT_NAME: "பெயர்",
    LOGIN_INPUT_MOBILE: "Mobile Number",
    LOGIN_INPUT_OTP: "OTP",
    LOGIN_BUTTON_SEND_OTP: "அனுப்பு OTP",
    LOGIN_BUTTON_SUBMIT_OTP: "Submit OTP",
    LOGIN_BUTTON_RESEND_OTP: "Didn’t get OTP? Resend",
    LOGIN_BUTTON_EDIT_NUMBER: "Edit Number",
    LOGIN_MESSAGE_OTP_SENT: "OTP Sent",
    LOGIN_MESSAGE_INVALID_OTP: "Please provide valid OTP",
    LOGIN_MESSAGE_OTP_ERROR: "அனுப்பும்போது ஏதோ தவறு ஏற்பட்டது OTP",
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR:
      "சமர்ப்பிக்கும் போது ஏதோ தவறு ஏற்பட்டது OTP",
    LOGIN_MESSAGE_INVALID_DATA: "சரியான தரவை நிரப்பவும்",
    SCAN_HEADING_TEETH: "பற்கள்",
    SCAN_INSTRUCTIONS_MAP: {
      front: [
        "வழிகாட்டி வீடியோவை கவனமாக பாருங்கள்",
        "சட்டத்தில் உங்கள் முன் கோண தாடை பொருத்தவும்",
        "கிளிக் பொத்தானைத் தேர்ந்தெடுக்கவும்",
      ],
      upper: [
        "வழிகாட்டி வீடியோவை கவனமாக பாருங்கள்",
        "சட்டத்தில் உங்கள் மேல் கோண தாடை பொருத்தவும்",
        "Select the click button",
      ],
      lower: [
        "வழிகாட்டி வீடியோவை கவனமாக பாருங்கள்",
        "உங்கள் கீழ் கோண தாடையை சட்டகத்தில் பொருத்தவும்",
        "கிளிக் பொத்தானைத் தேர்ந்தெடுக்கவும்",
      ],
    },
    SCAN_VIDEO_INSTRUCTIONS_MAP: {
      data: [
        "வழிகாட்டி வீடியோவை கவனமாக பாருங்கள்",
        "முடிந்தவரை நெருக்கமாக நிற்கவும். வீடியோவில் காட்டப்பட்டுள்ளபடி உங்கள் பற்களைக் காட்டுங்கள்.",
        "அனைத்து 3 கோணங்களும் பதிவு செய்யப்பட்டவுடன் இடைநிறுத்த பொத்தானைக் கிளிக் செய்யவும்.",
      ],
    },
    SCAN_SUBMIT_BUTTON: "ஊடுகதிர்",
    ANGLE_LIST: ["முன்", "மேல்", "கீழ்"],
    VIDEO_MODE: "வீடியோ பதிவு",
    REPORT_HEADING: "உங்கள் பல் மருத்துவ அறிக்கை",
    REPORT_SUB_SECTION_HEADING: "உங்களின் மதிப்பிடப்பட்ட சிகிச்சை விருப்பங்கள்",
    NO_DETECTIONS_IN_ANGLE:
      "நோய்கள் எதுவும் கண்டறியப்படவில்லை. படம் தவறாக இருந்தால், மீண்டும் முயற்சிக்கவும்.",
    BOOK_APPOINTMENT_BUTTON: "புத்தக நியமனம்",
    DONWLOAD_REPORT_BUTTON: "அறிக்கையைப் பதிவிறக்கவும்",
    EXIT_REPORT_BUTTON: "EXIT",
    APPOINTMENT_MESSAGE: "சந்திப்பை முன்பதிவு செய்ய இந்த எண்ணை அழைக்கவும்",
    APPOINTMENT_SECOND_SECTION_HEADING:
      "Fast Track & Evening Clinic at the School of Dental Sciences",
    APPOINTMENT_MORNING_SHIFT_TIMING: "Morning 9 am to Evening 5 pm",
    APPOINTMENT_EVENING_SHIFT_TIMING: "Evening 5 pm to 8.30 pm",
    DOWNLOAD_HEADING1:
      "சரியான வாய்வழி சுகாதார பழக்கம் மற்றும் சுகாதார நடைமுறைகளைப் பின்பற்ற DentalDost பயன்பாட்டைப் பயன்படுத்தவும்",
    DOWNLOAD_HEADING2: "இந்த QR குறியீட்டை ஸ்கேன் செய்து இப்போது பதிவிறக்கவும்",
  },
  doctorsMap: {
    1: {
      english: { name: "Dr.Sushma Bhandari", qualification: "BDS, MDS" },
      marathi: { name: "डॉ. सुषमा भंडारी", qualification: "बीडीएस, एमडीएस" },
      hindi: { name: "डॉ. सुषमा भंडारी", qualification: "बीडीएस, एमडीएस" },
    },
    2: {
      english: { name: "Dr.Rashmi Gangavati", qualification: "BDS, MDS" },
      marathi: { name: "डॉ. रश्मी गंगावती", qualification: "बीडीएस, एमडीएस" },
      hindi: { name: "डॉ. रश्मी गंगावती", qualification: "बीडीएस, एमडीएस" },
    },
    3: {
      english: { name: "Dr. Akhil Rao", qualification: "BDS, MDS" },
      marathi: { name: "डॉ. अखिल राव", qualification: "बीडीएस, एमडीएस" },
      hindi: { name: "डॉ. अखिल राव", qualification: "बीडीएस, एमडीएस" },
    },
    4: {
      english: { name: "Dr.Sameer Zope", qualification: "BDS, MDS" },
      marathi: { name: "डॉ. समीर झोपे", qualification: "बीडीएस, एमडीएस" },
      hindi: { name: "डॉ. समीर झोपे", qualification: "बीडीएस, एमडीएस" },
    },
    5: {
      english: { name: "Dr.Chinju Punnen", qualification: "BDS, MDS" },
      marathi: { name: "डॉ. चिंजू पुननेन", qualification: "बीडीएस, एमडीएस" },
      hindi: { name: "डॉ. चिंजू पुननेन", qualification: "बीडीएस, एमडीएस" },
    },
    6: {
      english: { name: "Dr.Sonali Waghmode", qualification: "BDS, MDS" },
      marathi: { name: "डॉ. सोनाली वाघमोडे", qualification: "बीडीएस, एमडीएस" },
      hindi: { name: "डॉ. सोनाली वाघमोडे", qualification: "बीडीएस, एमडीएस" },
    },
    7: {
      english: { name: "Dr. Shubha Joshi", qualification: "BDS, MDS" },
      marathi: { name: "डॉ. शुभा जोशी", qualification: "बीडीएस, एमडीएस" },
      hindi: { name: "डॉ. शुभा जोशी", qualification: "बीडीएस, एमडीएस" },
    },
    8: {
      english: { name: "Dr.Nitesh Akela", qualification: "BDS, MDS" },
      marathi: { name: "डॉ. नितेश अकेला", qualification: "बीडीएस, एमडीएस" },
      hindi: { name: "डॉ. नितेश अकेला", qualification: "बीडीएस, एमडीएस" },
    },
  },

  diseasesMap: {
    "pit and fissure caries": {
      color: "#0000FF",
      english: {
        name: "Pit and fissure caries",
        description: "Start of a tooth decay",
        treatment: "Tooth colour filling",
        price: "₹ 200 - 350",
      },
      marathi: {
        name: "थोडा कीड",
        description: "दात किडण्याची प्रारंभिक सुरुवात",
        treatment: "दाता-समान रंग असणारी फीलिन्ग",
        price: "₹ 200 - 350",
      },
      hindi: {
        name: "शुरुआती कीड़",
        description: "कीड़ की शुरुआत",
        treatment: "दाँत के रंग का फिलिंग",
        price: "₹ 200 - 350",
      },
      tamil: {
        name: "குழி மற்றும் பிளவு பூச்சிகள்",
        description: "பல் சிதைவின் ஆரம்பம்",
        treatment: "பல் நிற நிரப்புதல்கள்",
        price: "₹ 200 - 350",
      },
    },
    "deep caries": {
      color: "#FF8300",
      english: {
        name: "Deep caries",
        description: "A deeper cavity that might be painful",
        treatment:
          "Deep filling/ Root canal treatment according to Xray finding",
        price: "₹ 500 - 1140",
      },
      marathi: {
        name: "खोल कीड",
        description: "खोल कीड जी वेदनादायक असू शकते",
        treatment:
          "रूट कॅनॉल ट्रीटमेंट/फीलिन्ग करणे हे xray तपासणीवर अवलंबून आहे",
        price: "₹ 500 - 1140",
      },
      hindi: {
        name: "गहरा कीड़",
        description: "एक गहरा कीड़ा जो दर्दनाक हो सकता है",
        treatment: "ग़हरा फिलिंग/रूट कैनाल उपचार एक्सरे फाइंडिंग के अनुसार",
        price: "₹ 500 - 1140",
      },
      tamil: {
        name: "ஆழமான பூச்சிகள்",
        description: "வலிமிகுந்த ஆழமான குழி",
        treatment:
          "எக்ஸ்ரே கண்டுபிடிப்பின் படி ஆழமான நிரப்புதல் / ரூட் கால்வாய் சிகிச்சை",
        price: "₹ 500 - 1140",
      },
    },
    stains: {
      color: "#FF0000",
      english: {
        name: "Stains",
        description: "Brownish to blackish discoloration",
        treatment: "Teeth cleaning and polishing",
        price: "₹ 150",
      },
      marathi: {
        name: "दातांवर डाग",
        description: "तपकिरी ते काळ्या रंगाचा दाग",
        treatment: "दात स्वच्छ करणे",
        price: "₹ 150",
      },
      hindi: {
        name: "दांतों पर दाग",
        description: "भूरे से काले रंग का मलिनकिरण",
        treatment: "दांतो को सफाई",
        price: "₹ 150",
      },
      tamil: {
        name: "கறைகள்",
        description: "பழுப்பு நிறத்தில் இருந்து கருப்பு நிறமாக மாறுதல்",
        treatment: "பற்களை சுத்தம் செய்தல் மற்றும் பாலிஷ் செய்தல்",
        price: "₹ 150",
      },
    },
    calculus: {
      color: "#00FF00",
      english: {
        name: "Calculus",
        description: "Hard yellowish-white deposits on teeth/gums",
        treatment: "Deep Scaling",
        price: "₹ 150",
      },
      marathi: {
        name: "टार्टर",
        description: "दात/हिरड्यांवर कडक पिवळसर-पांढरा साठा",
        treatment: "संपूर्ण स्वच्छता आणि पॉलिशिंग",
        price: "₹ 150",
      },
      hindi: {
        name: "टार्टर",
        description: "दांतों/मसूड़ों पर सख्त पीला-सफेद जमाव",
        treatment: "गहराई से सफाई",
        price: "₹ 150",
      },
      tamil: {
        name: "கால்குலஸ்",
        description: "பற்கள்/ஈறுகளில் கடினமான மஞ்சள்-வெள்ளை படிவுகள்",
        treatment: "ஆழமான அளவிடுதல்",
        price: "₹ 150",
      },
    },
    "dental fluorosis": {
      color: "#FF9A8C",
      english: {
        name: "Fluorosis",
        description: "Whitish brown spots due to excess fluoride",
        treatment: "Veneer/ Cap",
        price: "₹ 920",
      },
      marathi: {
        name: "फ्लोरोसिस",
        description: "फ्लोराईड जास्त असल्यामुळे झालेले पांढरे तपकिरी डाग",
        treatment: "विनियर / कैप",
        price: "₹ 920",
      },
      hindi: {
        name: "फ्लोरोसिस",
        description: "अत्यधिक फ्लोराइड के कारण सफेद भूरे रंग के धब्बे",
        treatment: "विनियर / कैप",
        price: "₹ 920",
      },
      tamil: {
        name: "ஃப்ளோரோசிஸ்",
        description:
          "அதிகப்படியான புளோரைடு காரணமாக வெண்மை கலந்த பழுப்பு நிற புள்ளிகள்",
        treatment: "வெனீர் / தொப்பி",
        price: "₹ 920",
      },
    },
    "missing tooth": {
      color: "#4B8378",
      english: {
        name: "Missing tooth",
        description: "A gap due to missing tooth",
        treatment: "Implant/ Bridge/ Denture",
        price: "₹ 10000 / 900 / 200",
      },
      marathi: {
        name: "गहाळ दात",
        description: "दात नसल्यामुळे अंतर",
        treatment: "इम्प्लांट / ब्रिज / कवळी",
        price: "₹ 10000 / 900 / 200",
      },
      hindi: {
        name: "दांत में गॅप",
        description: "दांत न होने के कारण गैप",
        treatment: "इम्प्लांट / ब्रिज / डेंचर",
        price: "₹ 10000 / 900 / 200",
      },
      tamil: {
        name: "பல் காணவில்லை",
        description: "பல் இல்லாததால் ஒரு இடைவெளி",
        treatment: "உள்வைப்பு / பாலம் / பல்வகை",
        price: "₹ 10000 / 900 / 200",
      },
    },
    fracture: {
      color: "#808080",
      english: {
        name: "Fracture",
        description: "Semi or fully broken tooth",
        treatment: "Filling/ Cap",
        price: "₹ 200 / 920",
      },
      marathi: {
        name: "तुटलेला दात",
        description: "अर्धा किंवा पूर्ण तुटलेला दात",
        treatment: "फिलिंग / कॅप",
        price: "₹ 200 / 920",
      },
      hindi: {
        name: "टूटा हुआ दांत",
        description: "आधा या पूरी तरह से टूटा हुआ दांत",
        treatment: "फिलिंग / कैप",
        price: "₹ 200 / 920",
      },
      tamil: {
        name: "எலும்பு முறிவு",
        description: "அரை அல்லது முழுமையாக உடைந்த பல்",
        treatment: "நிரப்புதல் / தொப்பி",
        price: "₹ 200 / 920",
      },
    },
    "root stump": {
      color: "#FF00FF",
      english: {
        name: "Root stump",
        description: "Pieces of root/tooth remaining",
        treatment: "Extraction",
        price: "₹ 40",
      },
      marathi: {
        name: "रूट स्टंप",
        description: "कीड खोल असल्यामुळे फक्त दातांचे मूळ वाचले आहे",
        treatment: "दात काढणे",
        price: "₹ 40",
      },
      hindi: {
        name: "रूट स्टंप",
        description: "जड़/दाँत के बचे हुए टुकड़े",
        treatment: "दांत निकालना",
        price: "₹ 40",
      },
      tamil: {
        name: "ரூட் ஸ்டம்ப்",
        description: "மீதமுள்ள வேர்/பல் துண்டுகள்",
        treatment: "பிரித்தெடுத்தல்",
        price: "₹ 40",
      },
    },
    "grossly decayed": {
      color: "#FB6090",
      english: {
        name: "Grossly decayed tooth",
        description: "Minimum tooth structure left due to deep cavity",
        treatment: "Extraction",
        price: "₹ 40",
      },
      marathi: {
        name: "पूर्ण किडलेला दात",
        description: "कीड खोल गेल्यामुळे दातांचा लहान भाग शिल्लक आहे",
        treatment: "दात काढणे",
        price: "₹ 40",
      },
      hindi: {
        name: "पूरा खराब दांत",
        description:
          "गहरा कीड़ा होने के कारण दांत का केवल एक छोटा सा हिस्सा ही बचा है",
        treatment: "दांत निकालना",
        price: "₹ 40",
      },
      tamil: {
        name: "மொத்தமாக சிதைந்த பல்",
        description:
          "ஆழமான குழியின் காரணமாக குறைந்தபட்ச பல் அமைப்பு எஞ்சியிருக்கிறது",
        treatment: "பிரித்தெடுத்தல்",
        price: "₹ 40",
      },
    },
    malaligned: {
      color: "#FFFF00",
      english: {
        name: "Malaligned",
        description: "Crooked teeth, not in alignment",
        treatment: "Braces/ Clear Aligner",
        price: "₹ 15000",
      },
      marathi: {
        name: "वेडे-वाकडे दात",
        description: "दात संरेखित नाहीत",
        treatment: "ब्रेसेस / क्लिअर अलाइनर",
        price: "₹ 15000",
      },
      hindi: {
        name: "टेढे दांत",
        description: "टेढे मेढे दांत",
        treatment: "ब्रेसेस / क्लियर एलाइनर्स",
        price: "₹ 15000",
      },
      tamil: {
        name: "தவறானது",
        description: "வளைந்த பற்கள், சீரமைப்பில் இல்லை",
        treatment: "பிரேஸ்கள் / தெளிவான சீரமைப்பி",
        price: "₹ 15000",
      },
    },
    attrition: {
      color: "#00FFFF",
      english: {
        name: "Attrition",
        description: "Worn off tooth on biting surface",
        treatment: "Filling/ Night Guard",
        price: "₹ 200 / 920",
      },
      marathi: {
        name: "दातांची झीज झाली आहे",
        description: "दातांची अत्याधिक झीज झाल्याने ठणक लागू शकते",
        treatment: "फिलिंग / कॅप / नाईट गार्ड",
        price: "₹ 200 / 920",
      },
      hindi: {
        name: "दंत संघर्षण",
        description: "घिसा हुआ दांत",
        treatment: "फिलिंग / नाइट गार्ड",
        price: "₹ 200 / 920",
      },
      tamil: {
        name: "தேய்வு",
        description: "கடிக்கும் மேற்பரப்பில் பல் தேய்ந்தது",
        treatment: "நிரப்புதல் / இரவு காவலர்",
        price: "₹ 200 / 920",
      },
    },
    abfraction: {
      color: "#F9B208",
      english: {
        name: "Abfraction",
        description: "V-shaped defect on the tooth near gum",
        treatment: "Filling",
        price: "₹ 200",
      },
      marathi: {
        name: "अब्रेक्शन",
        description: "हिरड्याजवळच्या दातावर V आकाराचा दोष",
        treatment: "फिलिंग",
        price: "₹ 200",
      },
      hindi: {
        name: "अब्रेक्शन",
        description: "वी के आकार का दांत पर घिस जाना",
        treatment: "फिलिंग",
        price: "₹ 200",
      },
      tamil: {
        name: "சுருக்கம்",
        description: "ஈறுக்கு அருகில் உள்ள பல்லில் V வடிவ குறைபாடு",
        treatment: "நிரப்புதல்",
        price: "₹ 200",
      },
    },
    diastema: {
      color: "#7c6e61",
      english: {
        name: "Diastema",
        description: "A gap between upper front two teeth",
        treatment: "Filling / Veneer",
        price: "₹ 200 / 920",
      },
      marathi: {
        name: "डायस्टेमा",
        description: "वरच्या दोन दातांमधील अंतर",
        treatment: "विनियर / फिलिंग",
        price: "₹ 200 / 920",
      },
      hindi: {
        name: "सामने के दांतों के बीच फांक",
        description: "सामने के दांतों के बीच फांक",
        treatment: "विनियर / फिलिंग",
        price: "₹ 200 / 920",
      },
      tamil: {
        name: "டயஸ்டெமா",
        description: "மேல் முன் இரண்டு பற்களுக்கு இடையே ஒரு இடைவெளி",
        treatment: "நிரப்புதல் / வெனீர்",
        price: "₹ 200 / 920",
      },
    },
    "tooth gap": {
      color: "#3B0918",
      english: {
        name: "Tooth gap",
        description: "A gap between two teeth",
        treatment: "Orthodontic treatment",
        price: "₹ 15000",
      },
      marathi: {
        name: "दोन दातांमधील फट",
        description: "दातांमधील अंतर",
        treatment: "ब्रेसेस / क्लिअर अलाइनर",
        price: "₹ 15000",
      },
      hindi: {
        name: "दो दांतों के बीच फांक",
        description: "दो दांतों के बीच फांक",
        treatment: "दांतो को सीधा करने की इलाज",
        price: "₹ 15000",
      },
      tamil: {
        name: "பல் இடைவெளி",
        description: "இரண்டு பற்களுக்கு இடையே ஒரு இடைவெளி",
        treatment: "ஆர்த்தோடோன்டிக் சிகிச்சை",
        price: "₹ 15000",
      },
    },
    "silver filling": {
      color: "#73777B",
      english: {
        name: "Silver filling",
        description: "Black color filling which can be damaging in long run",
        treatment: "Replace with safe tooth colored filling",
        price: "₹ 200 - 350",
      },
      marathi: {
        name: "चांदी भरलेला दात",
        description: "चांदी मध्ये असलेला पारा हानिकारक आहे",
        treatment: "टूथ कलर फिलिंग्सने बदलून घ्या",
        price: "₹ 200 - 350",
      },
      hindi: {
        name: "सिल्वर फिलिंग",
        description: "चांदी/काले रंग का दाँत फिलिंग",
        treatment: "सुरक्षित दांत के रंग के फिलिंग से बदलें",
        price: "₹ 200 - 350",
      },
      tamil: {
        name: "வெள்ளி நிரப்புதல்",
        description: "கருப்பு நிற நிரப்புதல் நீண்ட காலத்திற்கு சேதமடையலாம்",
        treatment: "பாதுகாப்பான பல் நிற நிரப்புதலுடன் மாற்றவும்",
        price: "₹ 200 - 350",
      },
    },
    "composite filling": {
      color: "#C85C5C",
      english: {
        name: "Composite filling",
        description: "Tooth colored filling",
        treatment: "Replace if broken",
        price: "₹ 350",
      },
      marathi: {
        name: "कम्पोजिट फीलिंग",
        description: "दातासमान रंग असणारी फिलिंग",
        treatment: "तुटल्यास बदला",
        price: "₹ 350",
      },
      hindi: {
        name: "कम्पोजिट फीलिंग",
        description: "दांतों के रंग का भराव",
        treatment: "अगर टूटा हुआ है तो बदलें",
        price: "₹ 350",
      },
      tamil: {
        name: "கலப்பு நிரப்புதல்",
        description: "பல் நிற நிரப்புதல்",
        treatment: "உடைந்தால் மாற்றவும்",
        price: "₹ 350",
      },
    },
    "metal crown": {
      color: "#FF0075",
      english: {
        name: "Metal crown",
        description: "Black metal cap",
        treatment: "Keep it under observation",
        price: "FREE scan on DentalDost",
      },
      marathi: {
        name: "ब्लॅक मेटल कॅप",
        description: "काळ्या धातूचा कॅप",
        treatment: "निरीक्षणाखाली ठेवा",
        price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
      },
      hindi: {
        name: "मेटल कैप",
        description: "काली धातु के कैप",
        treatment: "निगरानी में रखें",
        price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
      },
      tamil: {
        name: "உலோக கிரீடம்",
        description: "கருப்பு உலோக தொப்பி",
        treatment: "அதை கண்காணிப்பில் வைக்கவும்",
        price: "DentalDost இல் இலவச ஸ்கேன்",
      },
    },
    "ceramic crown": {
      color: "#77D970",
      english: {
        name: "Ceramic crown",
        description: "Tooth coloured cap",
        treatment: "Keep it under observation",
        price: "FREE scan on DentalDost",
      },
      marathi: {
        name: "सिरेमिक कॅप",
        description: "दातासमान रंग असणारी कॅप",
        treatment: "निरीक्षणाखाली ठेवा",
        price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
      },
      hindi: {
        name: "सिरेमिक कैप",
        description: "दांत के रंग का कैप",
        treatment: "निगरानी में रखें",
        price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
      },
      tamil: {
        name: "பீங்கான் கிரீடம்",
        description: "பல் நிற தொப்பி",
        treatment: "அதை கண்காணிப்பில் வைக்கவும்",
        price: "DentalDost இல் இலவச ஸ்கேன்",
      },
    },
    abrasion: {
      color: "#774360",
      english: {
        name: "Abrasion",
        description: "Flattening of front surface of the tooth",
        treatment: "Tooth colour filling",
        price: "₹ 350",
      },
      marathi: {
        name: "दातांचे घर्षण",
        description: "दातांच्या समोरील भागाचे घर्षण",
        treatment: "दातासमान फिलिंग",
        price: "₹ 350",
      },
      hindi: {
        name: "दांतों का घर्षण",
        description: "दांतों के सामने की सतह का चपटा होना",
        treatment: "दांतो के रंग के फिलिंग",
        price: "₹ 350",
      },
      tamil: {
        name: "சிராய்ப்பு",
        description: "பல்லின் முன் மேற்பரப்பைத் தட்டையாக்குதல்",
        treatment: "பல் நிறத்தை நிரப்புதல்",
        price: "₹ 350",
      },
    },
    denture: {
      color: "#A64B2A",
      english: {
        name: "Denture",
        description: "Artificial set of teeth",
        treatment: "Maintain hygiene",
        price: "FREE scan on DentalDost",
      },
      marathi: {
        name: "कवळी",
        description: "खोट्या दातांनी बनवलेले कवळी",
        treatment: "स्वच्छता राखणे",
        price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
      },
      hindi: {
        name: "नकली दांत",
        description: "कृत्रिम दांतों की पंक्ति",
        treatment: "स्वच्छता बनाए रखें",
        price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
      },
      tamil: {
        name: "பல்வகை",
        description: "செயற்கை பற்களின் தொகுப்பு",
        treatment: "சுகாதாரத்தை பேணுங்கள்",
        price: "DentalDost இல் இலவச ஸ்கேன்",
      },
    },
    appliances: {
      color: "#B25068",
      english: {
        name: "Appliances",
        description: "Post ortho treatment retainer",
        treatment: "Maintain hygiene",
        price: "FREE scan on DentalDost",
      },
      marathi: {
        name: "उपकरण",
        description: "ऑर्थो ट्रीटमेंट झाल्यानंतर असलेले उपकरण",
        treatment: "स्वच्छता राखणे",
        price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
      },
      hindi: {
        name: "उपकरण",
        description: "ऑर्थो ट्रीटमेंट के बाद रिटेनर",
        treatment: "स्वच्छता बनाए रखें",
        price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
      },
      tamil: {
        name: "உபகரணங்கள்",
        description: "பிந்தைய ஆர்த்தோ சிகிச்சை தக்கவைப்பவர்",
        treatment: "சுகாதாரத்தை பேணுங்கள்",
        price: "DentalDost இல் இலவச ஸ்கேன்",
      },
    },
    "metal ceramic": {
      color: "#DACC96",
      english: {
        name: "Metal ceramic crown",
        description: "Tooth colored cap with metal lining",
        treatment: "Keep it under observation",
        price: "FREE scan on DentalDost",
      },
      marathi: {
        name: "मेटल सिरेमिक कॅप",
        description: "धातूच्या अस्तरांसह दात रंगीत कॅप",
        treatment: "निरीक्षणाखाली ठेवा",
        price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
      },
      hindi: {
        name: "मेटल सिरेमिक कैप",
        description: "चाँदी लाइनिंग के साथ दाँत कलर कैप",
        treatment: "निगरानी में रखें",
        price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
      },
      tamil: {
        name: "உலோக பீங்கான் கிரீடம்",
        description: "உலோகப் புறணியுடன் கூடிய பல் நிற தொப்பி",
        treatment: "அதை கண்காணிப்பில் வைக்கவும்",
        price: "DentalDost இல் இலவச ஸ்கேன்",
      },
    },
    "impacted 3rd molar / pericoronitis": {
      color: "#6D8B74",
      english: {
        name: "Impacted third molar",
        description: "Partially visible or not visible wisdom tooth",
        treatment: "Extraction",
        price: "₹ 100 - 500",
      },
      marathi: {
        name: "अक्कल दाढ",
        description: "अक्कल दाढ, अर्धी किंवा पुर्णपणे जबड्या मध्ये रूतलेली आहे",
        treatment: "अक्कल दाढ काढावी लागेल",
        price: "₹ 100 - 500",
      },
      hindi: {
        name: "अक्ल दाढ़",
        description:
          "आंशिक रूप से दिखाई देने वाला या न दिखाई देने वाला अकल दाढ़",
        treatment: "दांत निकालना",
        price: "₹ 100 - 500",
      },
      tamil: {
        name: "பாதிக்கப்பட்ட மூன்றாவது மோலார்",
        description: "ஓரளவு தெரியும் அல்லது தெரியாத ஞானப் பல்",
        treatment: "பிரித்தெடுத்தல்",
        price: "₹ 100 - 500",
      },
    },
    "discolored tooth": {
      color: "#F9CEEE",
      english: {
        name: "Discoloured tooth",
        description: "Changed tooth color than normal",
        treatment: "Root canal treatment",
        price: "₹ 990+",
      },
      marathi: {
        name: "रंग बदललेला दात",
        description: "दातांना मार लागल्यामुळे रंग बदल झालेला दात",
        treatment: "रूट कॅनल उपचार",
        price: "₹ 990+",
      },
      hindi: {
        name: "फीका पड़ा हुआ दाँत",
        description: "बदले हुए दांतो के रंग",
        treatment: "रुट कैनाल उपचार",
        price: "₹ 990+",
      },
      tamil: {
        name: "நிறம் மாறிய பல்",
        description: "இயல்பை விட பல் நிறம் மாறிவிட்டது",
        treatment: "ரூட் கால்வாய் சிகிச்சை",
        price: "₹ 990+",
      },
    },
    "food lodgement": {
      color: "#ef6e29",
      english: {
        name: "Food lodgement",
        description:
          "Food is stuck in and between your teeth, can indicate tooth decay",
        treatment: "Filling",
        price: "₹ 200 - 350",
      },
      marathi: {
        name: "दांतांमध्ये अन्न अडकलेले आहे",
        description: "दातामद्धे अन्न अडकत असल्यामुळे कीड असण्याची शक्यता आहे",
        treatment: "फिलिंग",
        price: "₹ 200 - 350",
      },
      hindi: {
        name: "दांतों में फंसा खाना",
        description: "खाना आपके दांतों में और उनके बीच फंसा हुआ है",
        treatment: "फिलिंग",
        price: "₹ 200 - 350",
      },
      tamil: {
        name: "உணவு தங்குமிடம்",
        description:
          "உங்கள் பற்களுக்கு இடையில் உணவு சிக்கிக்கொண்டால், அது பல் சிதைவைக் குறிக்கலாம்",
        treatment: "நிரப்புதல்",
        price: "₹ 200 - 350",
      },
    },
    braces: {
      color: "#F5A7A7",
      english: {
        name: "Braces",
        description: "Ongoing ortho treatment",
        treatment: "Maintain hygiene",
        price: "FREE scan on DentalDost",
      },
      marathi: {
        name: "ऑर्थो ब्रेसेस",
        description: "ऑर्थो उपचार सुरू आहे",
        treatment: "स्वच्छता राखणे",
        price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
      },
      hindi: {
        name: "दांतो में तार",
        description: "ऑर्थो का इलाज चल रहा है",
        treatment: "स्वच्छता बनाए रखें",
        price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
      },
      tamil: {
        name: "பிரேஸ்கள்",
        description: "தொடர்ந்து ஆர்த்தோ சிகிச்சை",
        treatment: "சுகாதாரத்தை பேணுங்கள்",
        price: "DentalDost இல் இலவச ஸ்கேன்",
      },
    },
    "receded gums": {
      color: "#AC7D88",
      english: {
        name: "Receded gums",
        description: "A part of root is visible due to gums changing position",
        treatment: "Gum Surgery",
        price: "₹ 300",
      },
      marathi: {
        name: "मूळ उघडे पडले आहे",
        description:
          "हिरड्यांची उंची कमी होते, लाल, सुजणे ही सुरुवातीची लक्षणे दिसतात",
        treatment: "हिरड्यांची शस्त्रक्रिया",
        price: "₹ 300",
      },
      hindi: {
        name: "दांतों की जड़ें उजागर हैं",
        description: "जड़ का एक भाग दिखाई देता है",
        treatment: "मसूड़े की सर्जरी",
        price: "₹ 300",
      },
      tamil: {
        name: "பின்வாங்கிய ஈறுகள்",
        description: "ஈறுகளின் நிலை மாறுவதால் வேரின் ஒரு பகுதி தெரியும்",
        treatment: "ஈறு அறுவை சிகிச்சை",
        price: "₹ 300",
      },
    },
    mesiodens: {
      color: "#82C4C3",
      english: {
        name: "Mesiodens",
        description: "Extra tooth present between upper two front teeth",
        treatment: "Extraction",
        price: "₹ 40",
      },
      marathi: {
        name: "अतिरिक्त दात",
        description: "हा एक अतिरिक्त दात आहे",
        treatment: "काढावी लागेल",
        price: "₹ 40",
      },
      hindi: {
        name: "अतिरिक्त दांत",
        description: "यह दांत अतिरिक्त है",
        treatment: "दांत निकालना",
        price: "₹ 40",
      },
      tamil: {
        name: "கூடுதல் பல்",
        description: "மேல் இரண்டு முன் பற்களுக்கு இடையில் கூடுதல் பல் உள்ளது",
        treatment: "பிரித்தெடுத்தல்",
        price: "₹ 40",
      },
      "incomplete rct": {
        color: "#BF9742",
        english: {
          name: "Incomplete RCT",
          description:
            "Root canal treatment is not complete and very harmful for the tooth",
          treatment: "Filling & Cap",
          price: "₹ 990 - 1140",
        },
        marathi: {
          name: "अपूर्ण रूट कॅनॉल ट्रीटमेंट",
          description:
            "अपूर्ण रूट कॅनॉल झाले असल्यास दातांमध्ये पुनः संक्रमण होऊ शकतो",
          treatment: "पूर्ण रूट कॅनाल उपचार",
          price: "₹ 990 - 1140",
        },
        hindi: {
          name: "अपूर्ण रूट कैनाल उपचार",
          description:
            "अपूर्ण रूट कैनाल उपचार जो दांतों के लिए हानिकारक होता है",
          treatment: "फिलिंग और कैप",
          price: "₹ 990 - 1140",
        },
        tamil: {
          name: "முழுமையற்ற RCT",
          description:
            "ரூட் கால்வாய் சிகிச்சை முழுமையடையாது மற்றும் பல்லுக்கு மிகவும் தீங்கு விளைவிக்கும்",
          treatment: "நிரப்புதல் & தொப்பி",
          price: "₹ 990 - 1140",
        },
      },
      "dislodged prosthesis / filling": {
        color: "#401f37",
        english: {
          name: "Dislodged prosthesis",
          description: "The filling/ prosthesis is either damaged or displaced",
          treatment: "Re-filling",
          price: "₹ 350",
        },
        marathi: {
          name: "निघालेले फीलिन्ग/ कॅप",
          description: "फीलिंग/ कॅप/ ब्रिज खराब किंवा विस्थापित झाले आहेत",
          treatment: "रिफिलिंग",
          price: "₹ 350",
        },
        hindi: {
          name: "ढीली कैप/ फिलिंग",
          description: "विस्थापित कैप,ब्रिज या फिलिंग",
          treatment: "दुबारा भरिये",
          price: "₹ 350",
        },
        tamil: {
          name: "சிதைந்த செயற்கை உறுப்பு",
          description:
            "நிரப்புதல்/புரோஸ்டெசிஸ் சேதமடைந்துள்ளது அல்லது இடம்பெயர்ந்துள்ளது",
          treatment: "மின்-தாக்கல்",
          price: "₹ 350",
        },
      },
      "inflammed / red gums": {
        color: "#661b40",
        english: {
          name: "Inflamed gum",
          description: "Red or swollen gums due to plaque & calculus",
          treatment: "Scaling",
          price: "₹ 150",
        },
        marathi: {
          name: "हिरड्यांना सूज",
          description: "सुजलेली आणि लाल झालेली हिरडी",
          treatment: "दात स्वच्छ करणे",
          price: "₹ 150",
        },
        hindi: {
          name: "सूजे मसूड़े",
          description: "लाल या सूजे हुए मसूड़े",
          treatment: "दांतो की सफाई",
          price: "₹ 150",
        },
        tamil: {
          name: "வீக்கமடைந்த ஈறு",
          description:
            "பிளேக் மற்றும் கால்குலஸ் காரணமாக சிவப்பு அல்லது வீங்கிய ஈறுகள்",
          treatment: "அளவிடுதல்",
          price: "₹ 150",
        },
      },
      "peg lateral": {
        color: "#DEB6AB",
        english: {
          name: "Peg lateral",
          description: "Smaller than normal upper front tooth",
          treatment: "Cap/ Veneer",
          price: "₹ 920",
        },
        marathi: {
          name: "छोटे दात",
          description: "समोरील छोटे दात",
          treatment: "विनियर / कॅप",
          price: "₹ 920",
        },
        hindi: {
          name: "छोटा दाँत",
          description: "सामने के छोटे दाँत",
          treatment: "विनियर/ कैप",
          price: "₹ 920",
        },
        tamil: {
          name: "ஆப்பு பக்கவாட்டு",
          description: "சாதாரண மேல் முன் பல்லை விட சிறியது",
          treatment: "தொப்பி / வெனீர்",
          price: "₹ 920",
        },
      },
    },
  },
};
