import { OPEN_KEYBOARD, CLOSE_KEYBOARD, UPDATE_INPUT_VALUE, BUTTON_PRESS } from './action';

const initialState = {
  isOpen: false,
  currentInput: null,
  value: '',
};

const maxLengths = {
  'name': 50,
  'otp': 6,
  'mobile': 10
}
function keyboardReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_KEYBOARD:
      return {
        ...state,
        isOpen: true,
        currentInput: action.payload.currentInput,
        value: action.payload.value,
      };
    case CLOSE_KEYBOARD:
      return {
        ...state,
        isOpen: false,
      };
    case UPDATE_INPUT_VALUE:
      return {
        ...state,
        value: action.payload.value,
      };
    case BUTTON_PRESS:
      const { button } = action.payload;
      let updatedValue = state.value;

      // Handle special button presses
      if (button === "{bksp}") {
        // Delete the last character from the value
        if (updatedValue.length > 0) {
          updatedValue = updatedValue.slice(0, -1);
        }
      } else if (button === "{shift}" || button === "{lock}" || button === "{enter}") {
        // Handle shift, lock, and enter buttons (you can add the necessary logic here)
      } else if (button === "{space}") {
        // Handle space button press
        updatedValue += " "; // Add a space character to the value
      } else {
        // Regular button press
        if (updatedValue === '') {
          // If value is empty, set the button value as the new value
          updatedValue = button;
        } else {
          // Append the button value to the current value
          updatedValue += button;
        }
      }
      return {
        ...state,

        value: maxLengths[state.currentInput] ? updatedValue.slice(0, maxLengths[state.currentInput]) : updatedValue
        // value: state.currentInput === "otp" ? updatedValue.slice(0, 6) : updatedValue,
      };

    default:
      return state;
  }
}
export { keyboardReducer, maxLengths };
