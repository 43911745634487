import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import CustomSnackBar from "../../components/CustomSnackBar";
import {
  getCurrentAngle,
  getCurrentAngleIndex,
  getTestId,
  getToken,
  setCurrentAngle,
  getTestMode,
} from "../../store/rootReducer";
import { CameraFeed } from "./camera-feed";
import VideoFeed from "./video-feed";
import { testSubmitedKiosk, API_URL } from "../../services/rootService";

export default function Capture() {
  const testId = useSelector(getTestId);
  const authToken = useSelector(getToken);
  const dispatch = useDispatch();
  const history = useHistory();
  const currentAngle = useSelector(getCurrentAngle);
  const currentAngleIndex = useSelector(getCurrentAngleIndex);
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarData, setSnackBarData] = React.useState({
    open: false,
    severity: "success",
    message: "",
  });
  // const webcamRef = React.useRef(null);
  // const mediaRecorderRef = React.useRef(null);
  // const [capturing, setCapturing] = useState(false);
  // const [recordedChunks, setRecordedChunks] = useState([]);
  // const [videoData, setVideoData] = useState();
  // const [testMode] = useState(labelsMap.TEST_MODE);
  const testMode = useSelector(getTestMode);

  const sendFile = async (file) => {
    try {
      if (testMode === "VIDEO") {
        history.push("/report");
      }
      var fileOfBlob = new File([file], testId + "file.mp4");
      const formData = new FormData();
      // formData.append("input_image", fileOfBlob);//for image test
      formData.append(
        testMode === "VIDEO" ? "input_video" : "input_image",
        testMode === "VIDEO" ? fileOfBlob : file
      );
      formData.append("test_id", testId);
      formData.append("angle", currentAngle); //for image test
      // formData.append("jwt", "f2edd9c4bf39d2835a93c07f4ce277a2"); //for image test
      formData.append(
        "jwt",
        testMode === "VIDEO"
          ? "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFAYi5jb20iLCJpZCI6IjYwNDlmMzQ5MzJlNzI1NWRiYTc4ZWU2MCIsImlhdCI6MTYzMjk5NTc5OX0.31HiHAhWQPzripze-3l9lWkcWU-C4l0qwasvhVH8uIU"
          : "f2edd9c4bf39d2835a93c07f4ce277a2"
      );
      formData.append("web", true);
      formData.append("draw", false);
      formData.append("rotate", "rotate");
      const response = await axios({
        method: "post",
        // url: "https://ai.dentaldost.co/add_task",//for image test
        url:
          testMode === "VIDEO"
            ? "https://ai.dentaldost.co/add_task_video"
            : "https://ai.dentaldost.co/add_task",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      });
      setIsLoading(false);
      if (testMode === "VIDEO") {
        if (response.status === 200) {
          await testSubmitedKiosk(authToken);
        } else {
          setSnackBarData({
            open: true,
            severity: "error",
            message: "Something went wrong please try again",
          });
        }
      } else {
        //for image test start
        if (currentAngleIndex + 1 < 3) {
          dispatch(setCurrentAngle(currentAngleIndex + 1));
          history.goBack();
        } else {
          dispatch(setCurrentAngle(0));
          await testSubmitedKiosk(authToken);
          history.push("/report");
        }
      }
      //for image test end
    } catch (error) {
      setIsLoading(false);
      setSnackBarData({
        open: true,
        severity: "error",
        message: "Something went wrong please try again",
      });
    }
  };

  // const handleDataAvailable = useCallback(
  //   ({ data }) => {
  //     if (data.size > 0) {
  //       setRecordedChunks((prev) => prev.concat(data));
  //     }
  //   },
  //   [setRecordedChunks]
  // );

  // const handleStartCaptureClick = useCallback(() => {
  //   setCapturing(true);
  //   mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
  //     mimeType: "video/webm",
  //   });
  //   mediaRecorderRef.current.addEventListener(
  //     "dataavailable",
  //     handleDataAvailable
  //   );
  //   mediaRecorderRef.current.start();
  // }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  // const handleStopCaptureClick = useCallback(() => {
  //   console.log("mediaRecorderRef.current", mediaRecorderRef.current);
  //   mediaRecorderRef.current.stop();
  //   setCapturing(false);
  // }, [mediaRecorderRef, setCapturing]);

  // const handleDownload = useCallback(() => {
  //   if (recordedChunks.length) {
  //     const blob = new Blob(recordedChunks, {
  //       type: "video/webm",
  //     });
  //     const url = URL.createObjectURL(blob);
  //     setVideoData(blob);
  //     uploadVideo(blob);
  //   }
  // }, [recordedChunks]);

  return (
    <div className="App capture-video">
      {testMode === "VIDEO" ? (
        <VideoFeed
          sendFile={sendFile}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        ></VideoFeed>
      ) : (
        <CameraFeed
          sendFile={sendFile}
          angle={currentAngle}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      <CustomSnackBar setSnackBarData={setSnackBarData} {...snackBarData} />
    </div>
  );
}
