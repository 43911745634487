import {
  testSubmitedKiosk
} from "./rootService";
import axios from "axios";
import audioGuide from "./audioGuide";
import deviceControl from "./deviceControl";
let isDetecting = false;

const initiateObjectDetector = async () => {
  objectDetector = await window.tflite.ObjectDetector.create(
    process.env.PUBLIC_URL + "/models/jaw_optimised.tflite", {
    maxResults: 1,
  }
  );
};
initiateObjectDetector();

const BRIGHTNESS_STEPS = 2;
const MAX_BRIGHTNESS = 200;
const INITIAL_HOLD_DELAY = 2000; // 2 seconds
const CAPTURE_DELAY = 3000; // 3 seconds
const ITERATION_DELAY = Math.floor(CAPTURE_DELAY / BRIGHTNESS_STEPS); // delay per brightness step

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

let objectDetector;
let authToken;
let classToServoPos = {
  UPPER: {
    angle: 90,
    threshold: 0.3,
    captureThreshold: 0.5,
    brightness: 150,
  },
  FRONT: {
    angle: 100,
    threshold: 0.3,
    captureThreshold: 0.5,
    brightness: 60,
  },
  LOWER: {
    angle: 110,
    threshold: 0.3,
    captureThreshold: 0.5,
    brightness: 150,
  },
};

let testId = "";
let stopVideoHook;
let setVideo;

const angleOrder = ["FRONT", "UPPER", "LOWER"];
const canvas = document.createElement("canvas");
const context = canvas.getContext("2d");
let currentAngleIndex = angleOrder.indexOf("FRONT");
const getNextPosition = (detectedClass) => {
  if (angleOrder[currentAngleIndex] === detectedClass) {
    currentAngleIndex = (currentAngleIndex + 1) % angleOrder.length;
  }

  return angleOrder[currentAngleIndex];
};

const processAngle = async (result, videoElement) => {
  // console.log(result)
  const classObj = result[0].classes[0];
  // console.log(classObj);
  let config = classToServoPos[classObj.className];
  if(config && config.brightness) {
    try {
      deviceControl.setLEDBrightness(config.brightness);
    } catch (error) {
      console.log("Something went wrong while setting the brightness of led");
    }
  }
  console.log(config, classObj.probability, config.threshold);
  if (config && classObj.probability >= config.captureThreshold) {
    await holdAndClickAngle(classObj, videoElement);

    await audioGuide.playSound("camera_click");
    await wait(1000);

    if (currentAngleIndex >= 2) {
      console.log("currentAngleIndex", currentAngleIndex, "STOPPING");
      await audioGuide.stopAllSounds();
      stopDetecting();
      return;
    }
    const nextPosition = getNextPosition(classObj.className);
    config = classToServoPos[nextPosition];
    console.log("Setting Video ", nextPosition.toLowerCase());
    setVideo(nextPosition.toLowerCase());
    audioGuidePlay();
    deviceControl.sendServoCommand(config.angle)
    deviceControl.ledOn()
  }
};
const holdAndClickAngle = async (classObj, videoElement) => {
  // let brightnessIncrement = Math.floor(MAX_BRIGHTNESS / BRIGHTNESS_STEPS);
  // let brightnessLevel = 0;

  await audioGuide.playSound("stay_still");
  await wait(INITIAL_HOLD_DELAY);

  for (let i = 0; i < BRIGHTNESS_STEPS; i++) {
    // deviceControl.setLEDBrightness(brightnessLevel);
    // brightnessLevel += brightnessIncrement;
    await audioGuide.playSound("hold_timer");
    await wait(ITERATION_DELAY);
    const image = await getFrame(videoElement);
    await sendFile(image.blobData, i+1);
  };
}

const getFrame = async (videoElement) => {
  return new Promise((resolve) => {
    canvas.height = 1920;
    canvas.width = 1080;
    context.save();
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.translate(canvas.width / 2, canvas.height / 2);
    context.rotate(Math.PI / 2);
    context.drawImage(
      videoElement,
      -videoElement.videoWidth / 2,
      -videoElement.videoHeight / 2,
      videoElement.videoWidth,
      videoElement.videoHeight
    );
    context.restore();

    canvas.toBlob((blob) => {
      resolve({
        imageData: context.getImageData(0, 0, canvas.width, canvas.height),
        blobData: blob
      });
    }, "image/png");
  });
}

const detectFrame = async (videoElement) => {
  const frameData = await getFrame(videoElement);
  const detect = await objectDetector.detect(frameData.imageData);
  return {
    detection: detect,
    imageBlob: frameData.blobData,
  };
};

export const detect = async (videoElement) => {
  if (!isDetecting) {
    return;
  }
  if (!objectDetector) {
    initiateObjectDetector();
  }

  let result = await detectFrame(videoElement);
  result = result.detection;

  const detectedClass = result[0].classes[0].className;
  if (angleOrder[currentAngleIndex] === detectedClass) {
    await processAngle(result, videoElement);
  }

  const nextFrame = () => {
    requestAnimationFrame(() => detect(videoElement));
  };

  nextFrame();
};
const audioGuidePlay = async () => {
  await audioGuide.playSound(angleOrder[currentAngleIndex]);
};
export const startDetecting = (
  videoElement,
  tid,
  token,
  setCurrentAngleVideo,
  stopVideo
) => {
  isDetecting = true;
  currentAngleIndex = 0;
  detect(videoElement);
  testId = tid;
  authToken = token;
  stopVideoHook = stopVideo;
  audioGuidePlay();
  setVideo = setCurrentAngleVideo;
};

export const stopDetecting = () => {
  isDetecting = false;
  currentAngleIndex = 0;
  // After updating highProbImages object
  deviceControl.resetServo();
  deviceControl.reset();

  deviceControl.ledOff();
  stopVideoHook();
};

const sendFile = async (file, index) => {
  try {
    const formData = new FormData();
    formData.append("input_image", file);
    formData.append("test_id", testId);
    formData.append("angle", angleOrder[currentAngleIndex].toLowerCase());
    formData.append("jwt", "f2edd9c4bf39d2835a93c07f4ce277a2");
    formData.append("web", true);
    formData.append("max_count", BRIGHTNESS_STEPS);
    formData.append("current_count", index);

    // Generate a unique number (timestamp)
    const uniqueNumber = new Date().getTime();

    const response = axios({
      method: "post",
      url: `https://ai.dentaldost.co/add_task_kiosk?timestamp=${uniqueNumber}`, // append uniqueNumber as a query parameter
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    console.log(error, " EXCEPTION ");
  }
};

// const uploadAngleImage = (fileBlob, fileName) => {
//   const formData = new FormData();

//   formData.append('file', fileBlob);
//   formData.append('fileName', fileName);

//   axios.post('http://localhost:8000/savefile', formData, {
//     headers: {
//       'Content-Type': 'multipart/form-data'
//     }
//   }).then(response => {
//     console.log(response);
//   }).catch(error => {
//     console.error(error);
//   });
// }