import { CircularProgress, IconButton } from "@material-ui/core";
import React, { Component } from "react";
import CameraAltOutlined from "@material-ui/icons/CameraAltOutlined";
import Check from "@material-ui/icons/Check";
import CancelOutlined from "@material-ui/icons/CancelOutlined";
import Grid from '@material-ui/core/Grid';

export class CameraFeed extends Component {
  state = {
    showPreview: false,
    devices: [],
  };
  /**
   * Processes available devices and identifies one by the label
   * @memberof CameraFeed
   * @instance
   */
  processDevices(devices) {
    let externalDevice = null;

    devices.forEach((device) => {
      this.setState({
        ...this.state,
        devices: [...this.state.devices, device.label],
      });

      // Find first device that is likely to be an external camera
      if (
        !device.label.toLowerCase().includes("front") &&
        !device.label.toLowerCase().includes("built-in")
      ) {
        if (!externalDevice) externalDevice = device;
      }
    });

    if (externalDevice) {
      this.setDevice(externalDevice);
    } else if (devices.length > 0) {
      // If no external device, fall back to first available device
      this.setDevice(devices[0]);
    }
  }

  /**
   * Sets the active device and starts playing the feed
   * @memberof CameraFeed
   * @instance
   */
  async setDevice(device) {
    const { deviceId } = device;
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        deviceId,
        height: 1080,
        width: 1920,
      },
    });

    this.videoPlayer.srcObject = stream;
    this.videoPlayer.play();
    // this.videoPlayer.stop();
  }

  /**
   * On mount, grab the users connected devices and process them
   * @memberof CameraFeed
   * @instance
   * @override
   */
  async componentDidMount() {
    const cameras = await navigator.mediaDevices.enumerateDevices();
    this.processDevices(cameras);
  }

  async componentWillUnmount() {
    // this.videoPlayer = {};
    // this.videoPlayer.srcObject.getTracks().forEach((track) => {
    //   track.stop();
    // });
  }

  /**
   * Handles taking a still image from the video feed on the camera
   * @memberof CameraFeed
   * @instance
   */
  takePhoto = () => {
    // The video's natural dimensions
    const videoWidth = this.videoPlayer.videoWidth;
    const videoHeight = this.videoPlayer.videoHeight;

    // Calculate the ratio
    const ratio = videoWidth / videoHeight;

    // Canvas dimensions
    const canvasWidth = 1920;
    const canvasHeight = canvasWidth / ratio;

    // Set canvas dimensions
    this.canvas.width = canvasWidth;
    this.canvas.height = canvasHeight;

    const context = this.canvas.getContext("2d");
    context.drawImage(this.videoPlayer, 0, 0, canvasWidth, canvasHeight);
  };

  sendPhoto = () => {
    const { sendFile, setIsLoading } = this.props;
    setIsLoading(true);
    this.canvas.toBlob(sendFile);
  };


  render() {
    return (

      <div
        className="c-camera-feed"
        style={{
          backgroundColor: "black",
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} md={12} lg={6} x1={6}>

            <div
              className="c-camera-feed__viewer container"
              style={{
                overflow: "hidden",
                display: this.state.showPreview ? "none" : "block",
                position: "relative", // Parent container with relative positioning
              }}
            >
              <video
                ref={(ref) => (this.videoPlayer = ref)}
                style={{ width: "100%", height: "100%", transform: "scaleX(-1)" }}
              />
              <img
                alt="logo"
                style={{
                  width: "100%",
                  position: "absolute", // Absolute positioning for the image
                  top: "50%", // Center vertically
                  left: "50%", // Center horizontally
                  transform: "translate(-50%, -50%)", // Correct offset
                  pointerEvents: "none", // To allow user interactions to pass through to the video
                }}
                src={
                  process.env.PUBLIC_URL +
                  "/images/" +
                  this.props.angle +
                  "-overlay.png"
                }
              />
              <IconButton
                onClick={() => {
                  this.takePhoto();
                  this.setState({ showPreview: true });
                }}
                style={{
                  color: "white",
                  backgroundColor: "grey",
                  height: "100px",
                  width: "100px",
                  position: "absolute", // Absolute positioning for the button
                  zIndex: "1001",
                  top: "50%",
                  transform: "translate(0%, -50%)",
                  left: "10px", // Position from the right
                }}
              >
                <CameraAltOutlined style={{ fontSize: 70 }} />
              </IconButton>

            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={6} x1={6}>

            <div
              style={{
                position: "relative",
              }}
            >
              <img
                alt="logo"
                style={{ width: "100%" }}
                src={
                  process.env.PUBLIC_URL +
                  "/images/" +
                  this.props.angle +
                  "-guide.png"
                }
              />
            </div>
          </Grid>

        </Grid>
        <div
          className="c-camera-feed__stage container"
          style={
            this.state.showPreview
              ? {
                width: "100vw",
                height: "100vh",
                position: "relative",
                display: "flex",
                alignItems: "center",
                position: "fixed",
                backgroundColor:"black"
              }
              : { display: "none" }
          }
        >
          <canvas
            id="dd-canvas"
            ref={(ref) => (this.canvas = ref)}
            style={{ width: "100%" }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
            position: "absolute",
            top: 0,
            left: 15,
            bottom: 0,
            width: "10vw",
          }}
        >
          <div
            style={{
              height: "250px",
              flexDirection: "column",
              width: "100%",
              display: "flex",
              alignContent: "center",
              justifyContent: "space-evenly",
              alignItems: "flex-end",
              position: "relative",
            }}
          >
            {!this.state.showPreview ? (
              <>

              </>
            ) : this.props.isLoading ? (
              <CircularProgress style={{ fontSize: 70, color: "white" }} />
            ) : (
              <>
                <IconButton
                  onClick={this.sendPhoto}
                  style={{
                    color: "white",
                    borderColor: "black",
                    backgroundColor: "grey",
                    height: "100px",
                    width: "100px",
                  }}
                >
                  <Check style={{ fontSize: 70 }} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    this.setState({ showPreview: false });
                  }}
                  style={{
                    color: "white",
                    borderColor: "black",
                    backgroundColor: "grey",
                    height: "100px",
                    width: "100px",
                  }}
                >
                  <CancelOutlined style={{ fontSize: 70 }} />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </div >
    );
  }
}
