import React, { useState, useRef, useEffect } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "./screenKeyboard.css";
import { useSelector, useDispatch } from 'react-redux';
import { buttonPress, closeKeyboard, updateInputValue } from './action'; // import actions

const ScreenKeyboard = () => {
  const [layoutName, setLayoutName] = useState("default");
  const [layout, setLayout] = useState({
    'default': ['1 2 3', '4 5 6', '7 8 9', '- 0 {bksp}'],
    'shift': ['1 2 3', '4 5 6', '7 8 9', '- 0 {bksp}']
  }); // default layout
  const clickSound = new Audio(process.env.PUBLIC_URL + "/audios/mixkit-modern-click-box-check-1120.wav"); // replace this with your file path


  const keyboard = useRef();
  const dispatch = useDispatch();
  const keyboardState = useSelector(state => state.keyboard); // access the keyboard state from redux store

  const onChange = (input, event) => {
    event.preventDefault();
    // dispatch(updateInputValue(input)); // dispatch action to update input value
  };

  const onKeyPress = (button, event) => {
    if (event) {
      event.preventDefault();
    }
    clickSound.play()

    dispatch(buttonPress(button));

    if (button === "{shift}" || button === "{lock}") handleShift();

    if (button === "{enter}" || button === "{tab}") {
      dispatch(closeKeyboard()); // dispatch action to close keyboard
    }
  };

  const handleShift = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default");
  };

  // ...add a useEffect hook to listen to changes to keyboardState.currentInput

  useEffect(() => {
    if (keyboardState.currentInput === "mobile" || keyboardState.currentInput === "otp") {
      setLayout({
        'default': ['1 2 3', '4 5 6', '7 8 9', '- 0 {bksp}'],
        'shift': ['1 2 3', '4 5 6', '7 8 9', '- 0 {bksp}']
      }); // number only layout
    } else {
      setLayout({
        'default': ['q w e r t y u i o p', 'a s d f g h j k l', 'z x c v b n m', '{shift} {space} {bksp}'],
        'shift': ['Q W E R T Y U I O P', 'A S D F G H J K L', 'Z X C V B N M', '{shift} {space} {bksp}']
      }); // standard layout
    }
  }, [keyboardState.currentInput]);

  return (
    keyboardState.isOpen ? (
      <div className="keyboard-wrapper" onClick={
        (e) => {
          e.preventDefault();
        }
      }>
        <Keyboard
          keyboardRef={(r) => (keyboard.current = r)}
          layoutName={layoutName}
          layout={layout}
          onChange={onChange}
          onKeyPress={onKeyPress}
          inputValue={keyboardState.value} // bind inputValue from Redux state
        />
      </div>) : null // don't render keyboard when isOpen is false
  );
};

export default ScreenKeyboard;

