import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./rootReducer";
import settings from "../containers/Setting/settingsReducer";
import { keyboardReducer } from "containers/keyboard/reducer";
export default configureStore({
  reducer: {
    root: rootReducer,
    settings: settings,
    // counter: counter,
    keyboard: keyboardReducer
  },
});
