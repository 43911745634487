import { createSlice } from "@reduxjs/toolkit";

const anglesList = ["front", "upper", "lower"];

// Initialize state from localStorage
const localStorageLocation = localStorage.getItem("location") || "";
const localStorageAutoHeightAdjust = JSON.parse(localStorage.getItem("autoHeightAdjust")) || false;
export const root = createSlice({
  name: "root",
  initialState: {
    language: "english",
    token: "",
    testId: "",
    currentAngleIndex: 0,
    name: "",
    mobile: "",
    testMode: "VIDEO",
    location: localStorageLocation,
    autoHeightAdjust: localStorageAutoHeightAdjust, // New autoHeightAdjust state
  },
  reducers: {
    changeLanguage: (state, { payload }) => {
      state.language = payload;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setTestId: (state, { payload }) => {
      state.testId = payload;
    },
    setCurrentAngle: (state, { payload }) => {
      state.currentAngleIndex = payload;
    },
    setUserDetails: (state, { payload }) => {
      state.name = payload.name;
      state.mobile = payload.mobile;
    },
    setTestMode: (state, { payload }) => {
      state.testMode = payload;
    },
    setLocation: (state, { payload }) => {
      state.location = payload;
      localStorage.setItem("location", payload);
    },
    setAutoHeightAdjust: (state, { payload }) => {
      state.autoHeightAdjust = payload;
      localStorage.setItem("autoHeightAdjust", JSON.stringify(payload)); // Store boolean in localStorage as string
    },
    resetState: (state, { }) => {
      state.language = "english";
      state.token = "";
      state.testId = "";
      state.currentAngleIndex = 0;
      state.name = "";
      state.mobile = "";
    },
  },
});

export const {
  changeLanguage,
  setToken,
  setTestId,
  setCurrentAngle,
  setUserDetails,
  resetState,
  setTestMode,
  setLocation,
  setAutoHeightAdjust
} = root.actions;
export const getLanguage = (state) => state.root.language;
export const getToken = (state) => state.root.token;
export const getTestId = (state) => state.root.testId;
export const getTestMode = (state) => state.root.testMode;
export const getCurrentAngleIndex = (state) => state.root.currentAngleIndex;
export const getCurrentAngle = (state) =>
  anglesList[state.root.currentAngleIndex];
export const getUserDetails = (state) => {
  return {
    name: state.root.name,
    mobile: state.root.mobile,
  };
};
export const getLocation = (state) => state.root.location; // New location selector
export const getAutoHeightAdjust = (state) => state.root.autoHeightAdjust; // New autoHeightAdjust selector

export default root.reducer;
