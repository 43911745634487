import React from "react";
import { Button, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getLanguage } from "../../store/rootReducer";
import { useHistory } from "react-router";
import { labelsMap } from "utils/i18n";

export default function Appointment() {
  const language = useSelector(getLanguage);
  const {
    APPOINTMENT_MESSAGE,
    APPOINTMENT_SECOND_SECTION_HEADING,
    APPOINTMENT_MORNING_SHIFT_TIMING,
    APPOINTMENT_EVENING_SHIFT_TIMING,
    EXIT_REPORT_BUTTON,
    DONWLOAD_REPORT_BUTTON,
  } = labelsMap[language];
  const history = useHistory();
  return (
    <div style={{ backgroundColor: "white", height: "100%" }}>
      <Grid container spacing={0} style={{
        height: "100%"
      }}>
        <Grid item xs={12} md={12} lg={6} x1={6}>
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundImage: `url(${process.env.PUBLIC_URL + "/images/appointment-background.png"
                })`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            {/* <div style={{ height: "2em" }}></div>
            <div
              style={{ textAlign: "left", paddingTop: "2em", width: "80%" }}
            ></div>
            <div style={{ height: "2em" }}></div> */}
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  alt="logo"
                  style={{ width: "12%", objectFit: "contain" }}
                  src={process.env.PUBLIC_URL + "/images/appointment-icon.png"}
                />
                &nbsp; &nbsp;
                <div
                  className="heading-appointment"
                  style={{ textAlign: "left" }}
                >
                  9172941466
                </div>
              </div>
              <div style={{ height: "4em" }}></div>
              <div
                className="heading-scan"
                style={{ textAlign: "center", width: "90%" }}
              >
                {APPOINTMENT_MESSAGE}
              </div>
            </div>
            <div style={{ height: "4em" }}></div>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div style={{ width: "50%" }}>
                  <Button
                    style={{ backgroundColor: "#052679", color: "white" }}
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      history.push("/download");
                    }}
                  >
                    <span style={{ fontSize: "1.5em" }}>
                      {DONWLOAD_REPORT_BUTTON}
                    </span>
                  </Button>
                </div>
                <div style={{ width: "40%" }}>
                  <Button
                    style={{ borderColor: "#052679", color: "#052679" }}
                    fullWidth
                    variant="outlined"
                    onClick={() => {
                      history.entries = [];
                      history.index = -1;
                      history.push("/");
                    }}
                  >
                    <span style={{ fontSize: "1.5em" }}>
                      {EXIT_REPORT_BUTTON}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={6} x1={6} style={{
          display:"flex",
          alignItems:"center"
        }}>
          <div
            style={{
              padding: "4vh",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "start",
                backgroundColor: "#D9E9FC",
                overflowY: "scroll",
              }}
            >
              <div
                style={{
                  textAlign: "right",
                  padding: "1em",
                }}
              >
                <img
                  alt="logo"
                  style={{ width: "25%", objectFit: "contain" }}
                  src={process.env.PUBLIC_URL + "/images/logo.png"}
                />
              </div>
              <div>
                <div className="heading-scan" style={{ textAlign: "left" }}>
                  {APPOINTMENT_SECOND_SECTION_HEADING}
                </div>
              </div>
              <div style={{ height: "1em" }}></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "start",
                  // backgroundColor: "#D9E9FC",
                  width: "90%",
                }}
              >
                <span style={{ width: "50%" }}>
                  {APPOINTMENT_MORNING_SHIFT_TIMING}
                </span>
                <hr style={{ width: "50%" }} />
              </div>
              {[
                [1, 2],
                [3, 4],
                [5, 6],
              ].map((item, index) => (
                <div
                  key={index}
                  style={{
                    textAlign: "left",
                    paddingTop: "1em",
                    paddingLeft: "2em",
                    paddingRight: "2em",
                    width: "100%",
                    marginBottom: "1em",
                    marginTop: "1em",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    {item.map((itemInner, indexInner) => (
                      <div
                        key={index + "-" + indexInner}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                          alignItems: "center",
                          backgroundColor: "#D9E9FC",
                          width: "50%",
                        }}
                      >
                        <img
                          alt="logo"
                          style={{ width: "20%", objectFit: "contain" }}
                          src={
                            process.env.PUBLIC_URL +
                            "/images/appointment-page.png"
                          }
                        />
                        &nbsp; &nbsp;
                        <div>
                          <span className="doctor-name">
                            {labelsMap.doctorsMap[itemInner][language].name}
                          </span>
                          <br />
                          <span style={{ fontSize: 15 }}>
                            {
                              labelsMap.doctorsMap[itemInner][language]
                                .qualification
                            }
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              <div style={{ height: "1em" }}></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "start",
                  // backgroundColor: "#D9E9FC",
                  width: "90%",
                }}
              >
                <span style={{ width: "35%" }}>
                  {APPOINTMENT_EVENING_SHIFT_TIMING}
                </span>
                <hr style={{ width: "65%" }} />
              </div>
              {[[7, 8]].map((item, index) => (
                <div
                  key={index}
                  style={{
                    textAlign: "left",
                    paddingTop: "1em",
                    paddingLeft: "2em",
                    paddingRight: "2em",
                    width: "100%",
                    marginBottom: "1em",
                    marginTop: "1em",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    {item.map((itemInner, indexInner) => (
                      <div
                        key={index + "-" + indexInner}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                          alignItems: "center",
                          backgroundColor: "#D9E9FC",
                          width: "50%",
                        }}
                      >
                        <img
                          alt="logo"
                          style={{ width: "20%", objectFit: "contain" }}
                          src={
                            process.env.PUBLIC_URL +
                            "/images/appointment-page.png"
                          }
                        />
                        &nbsp; &nbsp;
                        <div>
                          <span className="doctor-name">
                            {labelsMap.doctorsMap[itemInner][language].name}
                          </span>
                          <br />
                          <span style={{ fontSize: 15 }}>
                            {
                              labelsMap.doctorsMap[itemInner][language]
                                .qualification
                            }
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
