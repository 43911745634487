import io from "socket.io-client";

class DeviceControl {
    initSocket() {
        this.socket = io.connect('http://localhost:5000', {
            reconnection: true, // Enable auto reconnection
            reconnectionDelay: 1000, // Delay before attempting to reconnect
            reconnectionAttempts: Infinity // Try to reconnect forever
        });

        this.socket.on('connect', () => {
            this.isConnected = true;
            console.log('Connected to server');
        });

        this.socket.on('connect_error', (error) => {
            this.isConnected = false;
            console.error('Connection error:', error);
        });

        this.socket.on('disconnect', () => {
            this.isConnected = false;
            console.log('Disconnected from server');
        });

        this.socket.on('reconnect', (attemptNumber) => {
            this.isConnected = true;
            console.log('Reconnected to server after', attemptNumber, 'attempts');
        });
    }
    constructor() {
        this.initSocket();
        // Preset values for the LED
        this.defaultBrightness = 150;
        this.defaultColor = {
            r: 150,
            g: 255,
            b: 150
        }; // white light
        // you can use the "connect" and "disconnect" events to keep track of the connection status
        this.socket.on('connect', () => {
            this.isConnected = true;
        });
        this.socket.on('disconnect', () => {
            this.isConnected = false;
        });
    }

    sendServoCommand(position) {
        if (!this.isConnected) {
            console.error('Unable to send servo command, not connected');
            return;
        }
        if (position >= 0 && position <= 180) {
            this.socket.emit('servo command', position);
        } else {
            console.error('Invalid servo position');
        }
    }

    sendLEDCommand(brightness, r, g, b) {
        if (!this.isConnected) {
            console.error('Unable to send servo command, not connected');
            return;
        }
        if (brightness >= 0 && brightness <= 255 &&
            r >= 0 && r <= 255 &&
            g >= 0 && g <= 255 &&
            b >= 0 && b <= 255) {
            this.socket.emit('led command', {
                brightness,
                r,
                g,
                b
            });
            // this.socket.emit('led command', { brightness, r, g, b });
        } else {
            console.error('Invalid LED parameters');
        }
    }

    ledOn() {

        // Turn the LED on with preset brightness and color
        this.sendLEDCommand(this.defaultBrightness, this.defaultColor.r, this.defaultColor.g, this.defaultColor.b);
    }

    ledOff() {
        // Turn the LED off
        this.sendLEDCommand(0, 0, 0, 0);
    }

    resetServo() {
        // reset servo to 100 degrees and LED to off state
        this.sendServoCommand(100);
    }

    isConnected() {
        return this.isConnected;
    }

    setLEDBrightness(brightness) {
        this.sendLEDCommand(brightness, this.defaultColor.r, this.defaultColor.g, this.defaultColor.b);
    }

    moveUp() {
        if (!this.isConnected) {
            console.error('Unable to send motor command, not connected');
            return;
        }
        this.socket.emit('motor command', { direction: 1 });

    }

    moveUpStep(stepDuration = 2000) {
        if (!this.isConnected) {
            console.error('Unable to send motor command, not connected');
            return;
        }
        this.socket.emit('motor command', { direction: 1 });
        setTimeout(() => {
            this.socket.emit('motor command', { direction: 0 });
        }, stepDuration);
    }

    moveDownStep(stepDuration = 2000) {
        if (!this.isConnected) {
            console.error('Unable to send motor command, not connected');
            return;
        }
        this.socket.emit('motor command', { direction: -1 });
        setTimeout(() => {
            this.socket.emit('motor command', { direction: 0 });
        }, stepDuration);
    }

    moveDown() {
        if (!this.isConnected) {
            console.error('Unable to send motor command, not connected');
            return;
        }
        this.socket.emit('motor command', { direction: -1 });

    }

    stop() {
        if (!this.isConnected) {
            console.error('Unable to send motor command, not connected');
            return;
        }
        this.socket.emit('motor command', { direction: 0 });
    }
    reset() {
        // Reset motor by moving down at the reset speed
        this.moveDown();
    }

}

export default new DeviceControl();