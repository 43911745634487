import React, { useCallback, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { getAutoHeightAdjust, getTestId, getToken } from "../../store/rootReducer";
import Webcam from "react-webcam";
import { IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./capture.css";
import { useHistory } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { startDetecting } from "./../../services/objectDetectionService";
import { testSubmitedKiosk } from "services/rootService";
import audioGuide from "services/audioGuide";
import deviceControl from "services/deviceControl";
import heightDetection from "services/heightDetection";
import { FaArrowUp, FaCheck, FaArrowDown } from 'react-icons/fa';

const VideoFeed = ({ sendFile }) => {
  const VIDEO_TIMEOUT = 60;
  const history = useHistory();
  const videoRef = useRef();

  const testId = useSelector(getTestId);
  const [isLoading, setIsLoading] = useState(false);
  // const [snackBarData, setSnackBarData] = React.useState({
  //   open: false,
  //   severity: "success",
  //   message: "",
  // });
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  // const [videoData, setVideoData] = useState();
  const [currentAngleVideo, setCurrentAngleVideo] = useState("front");
  const [seconds, setSeconds] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const authToken = useSelector(getToken);
  const autoHeightAdjust = useSelector(getAutoHeightAdjust)
  const [showManualheightButtons, setShowManualheightButtons] = useState(false);
  const [ongoingScan, setOngoingScan] = useState(false);
  let faceApiService;

  const uploadVideo = async (file) => {
    try {
      var fileOfBlob = new File([file], testId + "file.mp4");
      sendFile(fileOfBlob);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const startScan = () => {
    setOngoingScan(true);
    startDetecting(webcamRef.current.video, testId, authToken, setCurrentAngleVideo, handleStopCaptureClick);
  }

  const handleStartCaptureClick = useCallback(async () => {
    try {

      setCapturing(true);
      if (autoHeightAdjust) {
        await heightDetection.alignCamera(webcamRef.current.video);
        setShowManualheightButtons(true);
      }
      else {
        startScan();
      }
      // heightDetection.start();

    } catch (error) {
      setCapturing(false);
      deviceControl.reset();
      deviceControl.ledOn();
      console.error(error);
    }

  }, [webcamRef, setCapturing, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(async () => {
    setOngoingScan(false);
    setShowManualheightButtons(false);
    setIsLoading(true);
    setCapturing(false);
    clearInterval(intervalId);
    await testSubmitedKiosk(authToken);
    history.push("/report");
  }, [setCapturing]);

  // useEffect(() => {
  //   if (recordedChunks.length) {
  //     const blob = new Blob(recordedChunks, {
  //       type: "video/webm",
  //     });
  //     // const url = URL.createObjectURL(blob);
  //     // setVideoData(blob);
  //     uploadVideo(blob);
  //   }
  // }, [recordedChunks]);

  // const handleDownload = useCallback(() => {
  //   console.log("recordedChunks", recordedChunks);
  //   if (recordedChunks.length) {
  //     const blob = new Blob(recordedChunks, {
  //       type: "video/webm",
  //     });
  //     const url = URL.createObjectURL(blob);
  //     // setVideoData(blob);
  //     // uploadVideo(blob);
  //   }
  // }, [recordedChunks]);

  const videoConstraints = {
    facingMode: "user",
    width: 1920,//640,
    height: 1080//480,
  };
  const [scale, setScale] = useState({ x: 0.75, y: 0.75 });

  useEffect(() => {
    const updateScale = () => {
      if (window.innerWidth > window.innerHeight) {
        // landscape
        setScale({ x: 1.13, y: 1.13 }); // adjust these values as necessary
      } else {
        // portrait
        setScale({ x: 0.75, y: 0.75 }); // adjust these values as necessary
      }
    };
    setTimeout(async () => {
      await audioGuide.playSound("adjust_camera_height");
    }, 2000);

    window.addEventListener("resize", updateScale);
    updateScale(); // initial call to set the scale
    deviceControl.resetServo();
    deviceControl.ledOn();
  }, []);

  useEffect(() => {
    console.log("New Angle video", currentAngleVideo);
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [currentAngleVideo]);

  const handleStepUpClick = () => {
    deviceControl.moveUpStep();
  };
  const handleStepDownClick = () => {
    deviceControl.moveDownStep();
  };
  const handleConfirmClick = () => {
    startScan();
  };
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={12} lg={6} x1={6}>
        <div
          style={{
            position: "relative",
            height: "100%",
          }}
        >
          <div
            className="c-camera-feed"
            style={{
              backgroundColor: "black",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="c-camera-feed__viewer container" style={{}}>
              <>
                <div
                  className="c-camera-feed__viewer container"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                >
                  <Webcam
                    height="100%"
                    width="100%"
                    audio={false}
                    mirrored={true}
                    ref={webcamRef}
                    videoConstraints={videoConstraints}
                    style={{
                      transform: `rotate(270deg) scaleX(${scale.x}) scaleY(${scale.y})`,
                    }}
                  />
                </div>

                {/* <div class="centered">
        <img
        alt="logo"
        style={{ width: 1920, height: 1080 }}
        src={
            process.env.PUBLIC_URL +
            "/images/" +
            "front" +
            "-overlay.png"
        }
        />
    </div> */}
              </>
            </div>
            <div
              style={{
                position: "absolute",
                left: "30px",
                zIndex: 1000,
                top: "50%",
                transform: "translateY(-50%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "30px",
              }}
            >
              {capturing || isLoading ? (
                <>
                  {showManualheightButtons ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        backgroundColor: "black",
                        padding: "10px",
                        height: "300px"
                      }}
                    >
                      <FaArrowUp size={50} color="white" onClick={handleStepUpClick} />
                      {!ongoingScan && (
                        <FaCheck size={50} color="white" onClick={handleConfirmClick} />
                      )}
                      <FaArrowDown size={50} color="white" onClick={handleStepDownClick} />
                    </div>

                  ) : (<></>)}
                </>
              ) : (
                <>

                  {
                    autoHeightAdjust ? (
                      <Button
                        className="capture-button"
                        onClick={handleStartCaptureClick}
                        style={{ color: "white", borderColor: "white", backgroundColor: "#002446", fontSize: "30px", width: "130px" }}
                      >
                        Start
                      </Button>
                    ) : (
                      <Button
                        className="capture-button"
                        onClick={handleStartCaptureClick}
                        style={{ color: "white", borderColor: "white", backgroundColor: "#002446", fontSize: "30px", width: "130px" }}
                      >
                        Scan
                      </Button>
                    )
                  }
                  <img
                    className="bouncingPointer bouncingPointerVideoPosition"
                    src={process.env.PUBLIC_URL + "/images/pointer.png"}
                    width="50"
                    height="50"
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={12} lg={6} x1={6}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div
            style={{
              width: "95%",
            }}
          >
            <video muted ref={videoRef} width="100%" loop autoPlay>
              <source
                src={
                  process.env.PUBLIC_URL +
                  "/videos/" +
                  currentAngleVideo +
                  "-angle.mp4"
                }
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </Grid>
    </Grid >
  );
};

export default VideoFeed;
