import "@mediapipe/face_detection";
import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-backend-webgl";
import * as faceDetection from "@tensorflow-models/face-detection";
import deviceControl from './deviceControl';

class FaceDetectionService {
  constructor() {
    this.model = faceDetection.SupportedModels.MediaPipeFaceDetector;
    this.detectorConfig = {
      runtime: "mediapipe",
      solutionPath: "https://cdn.jsdelivr.net/npm/@mediapipe/face_detection",
      maxFaces: 1
    };
    (async () => {
      this.detector = await faceDetection.createDetector(this.model, this.detectorConfig);
      console.log(this.detector, 'Model Loaded');
    })();
    this.canvas = document.createElement("canvas");
    this.context = this.canvas.getContext("2d");
    this.shouldContinueDetection = true;

  }

  moveMotor(direction) {
    console.log("Moving motor", direction);
    if (direction === 'up') {
      deviceControl.moveUp();
    } else if (direction === 'down') {
      deviceControl.moveDown();
    }
  }

  // Method to align camera

  async alignCamera(videoElement) {
    return new Promise(async (resolve, reject) => {
      this.shouldContinueDetection = true;
      let faceFound = false;
      let lastMovementCommand = null;

      // Set the video element
      this.video = videoElement;
      this.canvas.height = 1024;
      this.canvas.width = 576;

      const detect = async () => {
        if (!this.shouldContinueDetection) {
          return;
        }

        this.context.save();
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.context.translate(this.canvas.width / 2, this.canvas.height / 2);
        this.context.rotate(Math.PI / 2);
        this.context.drawImage(this.video, -this.video.videoWidth / 2, -this.video.videoHeight / 2, this.video.videoWidth, this.video.videoHeight);
        this.context.restore();

        let imageData = this.context.getImageData(0, 0, this.canvas.width, this.canvas.height);
        const predictions = await this.detector.estimateFaces(imageData);

        if (predictions.length > 0) {
          faceFound = true;
          console.log(predictions, "predictions");
          const keypoints = predictions[0].keypoints;
          const nose = keypoints.find(keypoint => keypoint.name === 'noseTip');
          const noseYPosition = nose.y;

          // Define the buffer zone, for example, 10% of the frame height
          const bufferZone = this.canvas.height * 0.10;
          const center = this.canvas.height / 2;
          const upperLimit = center + bufferZone / 2;
          const lowerLimit = center - bufferZone / 2;

          console.log(`Nose position: ${noseYPosition}`, lastMovementCommand);
          console.log(`Upper limit: ${upperLimit}, Lower limit: ${lowerLimit}`);

          // If nose position is outside the buffer zone, move the motor
          if (noseYPosition < lowerLimit && lastMovementCommand !== 'up') {
            console.log("Action: Moving motor up");
            this.moveMotor('up');
            lastMovementCommand = 'up';
          } else if (noseYPosition > upperLimit && lastMovementCommand !== 'down') {
            console.log("Action: Moving motor down");
            this.moveMotor('down');
            lastMovementCommand = 'down';
          } else if (noseYPosition >= lowerLimit && noseYPosition <= upperLimit && lastMovementCommand !== 'stop') {
            console.log("Action: Stopping motor");
            this.shouldContinueDetection = false;
            resolve();
            deviceControl.stop();
            lastMovementCommand = 'stop';
          }
        } else if (!faceFound && lastMovementCommand !== 'up') {
          console.log("Action: Moving motor up");
          this.moveMotor('up');
          lastMovementCommand = 'up';
        }

        console.log(predictions);
        requestAnimationFrame(detect);
      };

      await detect();

      // If no face is detected within 30 seconds, reject the promise with an error
      setTimeout(() => {
        if (!faceFound) {
          this.shouldContinueDetection = false;
          reject(new Error('No face detected within 30 seconds'));
        }
      }, 30000);
    });
  }

}

export default new FaceDetectionService();
